import { mixins } from "@/plugins/mixins";
export default {
  name: "rideCard",
  mixins: [mixins],
  data() {
    return {
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '用户',
        prop: 'customerName'
      }, {
        label: '骑行卡',
        prop: 'rideCardName'
      }, {
        label: '购买时间',
        prop: 'purchaseTime'
      }, {
        label: '购买价格',
        prop: 'nowPrice'
      }, {
        label: '到期时间',
        prop: 'expireTime',
        width: '170px'
      }, {
        label: '有效时长',
        prop: 'effectiveDay',
        customRender: props => {
          return props.row.effectiveDay + '天';
        }
      }, {
        label: '次数',
        prop: 'effectiveCount',
        customRender: props => {
          return props.row.effectiveCount === 0 ? '不限' : props.row.effectiveCount;
        }
      }, {
        label: '已使用次数',
        prop: 'usedCount'
      }, {
        label: '每单最高抵扣额',
        prop: 'deductionFee'
      }, {
        label: '支付状态',
        prop: 'payStatus'
      }],
      topButtons: [],
      linkButtons: [],
      // 搜索字段
      searchItems: [{
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '购买时间',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }]
    };
  },
  methods: {}
};